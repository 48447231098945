var render = function render(){
  var _vm$giftcard$thumb, _vm$giftcard$thumb2, _vm$giftcard$thumb3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1280"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.giftcard._id ? _c('span', [_vm._v("상품상세")]) : _c('span', [_vm._v("상품등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("카테고리")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.giftcard._categories.push(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" 카테고리 추가")], 1)], 1), _c('v-card-text', _vm._l(_vm.giftcard._categories, function (_category, index) {
    return _c('v-layout', {
      key: index,
      class: {
        'mt-4': index ? true : false
      },
      attrs: {
        "align-end": ""
      }
    }, [_c('category-select', {
      attrs: {
        "categories": _vm.categories,
        "hide-details": "",
        "allowDepth": [1, 2]
      },
      model: {
        value: _vm.giftcard._categories[index],
        callback: function ($$v) {
          _vm.$set(_vm.giftcard._categories, index, $$v);
        },
        expression: "giftcard._categories[index]"
      }
    }), 1 < _vm.giftcard._categories.length ? _c('v-btn', {
      staticClass: "ml-2",
      attrs: {
        "small": "",
        "text": "",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.giftcard._categories.splice(index, 1);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "20"
      }
    }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1);
  }), 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "pr-md-1",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-layout', {
    attrs: {
      "no-gutters": "",
      "align-center": ""
    }
  }, [_vm.thumb || _vm.giftcard.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.thumb) || ((_vm$giftcard$thumb = _vm.giftcard.thumb) === null || _vm$giftcard$thumb === void 0 ? void 0 : _vm$giftcard$thumb.path) || ((_vm$giftcard$thumb2 = _vm.giftcard.thumb) === null || _vm$giftcard$thumb2 === void 0 ? void 0 : _vm$giftcard$thumb2.src),
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _c('v-sheet', {
    attrs: {
      "width": "96",
      "height": "96",
      "color": "d-flex justify-center align-center grey lighten-2"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    attrs: {
      "placeholder": (_vm$giftcard$thumb3 = _vm.giftcard.thumb) === null || _vm$giftcard$thumb3 === void 0 ? void 0 : _vm$giftcard$thumb3.name
    },
    on: {
      "change": function (file) {
        return _vm.uploadThumb(file);
      }
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("이미지")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.giftcard.images.push(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("이미지추가")], 1)], 1), _c('v-card-text', [_c('v-draggable', {
    model: {
      value: _vm.giftcard.images,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "images", $$v);
      },
      expression: "giftcard.images"
    }
  }, _vm._l(_vm.giftcard.images, function (image, index) {
    var _vm$giftcard$images$i;
    return _c('v-layout', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "no-gutters": "",
        "align-center": ""
      }
    }, [_vm.images[index] || _vm.giftcard.images[index] ? _c('v-img', {
      attrs: {
        "src": _vm.createObjectURL(_vm.images[index]) || _vm.giftcard.images[index].path || _vm.giftcard.images[index].src,
        "max-width": "96",
        "height": "auto",
        "contain": ""
      }
    }) : _c('v-sheet', {
      attrs: {
        "width": "96",
        "height": "96",
        "color": "d-flex justify-center align-center grey lighten-2"
      }
    }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
      attrs: {
        "placeholder": (_vm$giftcard$images$i = _vm.giftcard.images[index]) === null || _vm$giftcard$images$i === void 0 ? void 0 : _vm$giftcard$images$i.name
      },
      on: {
        "change": function (file) {
          return _vm.uploadImage(file, index);
        }
      },
      model: {
        value: _vm.images[index],
        callback: function ($$v) {
          _vm.$set(_vm.images, index, $$v);
        },
        expression: "images[index]"
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("기본정보")])])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "상품권이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.name,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "name", $$v);
      },
      expression: "giftcard.name"
    }
  }), _vm.siteInfo.giftcardAliasEnabled ? _c('v-text-field', {
    attrs: {
      "label": "상품권별칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.alias,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "alias", $$v);
      },
      expression: "giftcard.alias"
    }
  }) : _vm._e(), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "상품권코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.code,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "code", $$v);
      },
      expression: "giftcard.code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "진열순서",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.no,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "no", $$v);
      },
      expression: "giftcard.no"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "label": "액면가",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.faceamt,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "faceamt", $$v);
      },
      expression: "giftcard.faceamt"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "label": "판매가",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.salePrice,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "salePrice", $$v);
      },
      expression: "giftcard.salePrice"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "label": "재고",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.stock,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "stock", $$v);
      },
      expression: "giftcard.stock"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("신용카드 결제 수수료")])]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "shrink",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "suffix": "%"
    },
    model: {
      value: _vm.giftcard.commission.creditcard,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard.commission, "creditcard", $$v);
      },
      expression: "giftcard.commission.creditcard"
    }
  })], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("모바일 결제 수수료")])]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "shrink",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "suffix": "%"
    },
    model: {
      value: _vm.giftcard.commission.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard.commission, "mobile", $$v);
      },
      expression: "giftcard.commission.mobile"
    }
  })], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("절사(절삭) 단위")])]), _c('v-spacer'), _c('v-select', {
    staticClass: "shrink",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "items": [1, 10, 100, 1000],
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "suffix": "원"
    },
    model: {
      value: _vm.giftcard.commission.cutUnit,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard.commission, "cutUnit", $$v);
      },
      expression: "giftcard.commission.cutUnit"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("신용카드결제")])]), _c('v-spacer'), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "creditcard",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.paymentMethods,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "paymentMethods", $$v);
      },
      expression: "giftcard.paymentMethods"
    }
  })], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("모바일결제")])]), _c('v-spacer'), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "mobile",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.paymentMethods,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "paymentMethods", $$v);
      },
      expression: "giftcard.paymentMethods"
    }
  })], 1), _c('v-divider'), _vm.siteInfo.paymentMethods.includes(`simple`) ? _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("간편결제")])]), _c('v-spacer'), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "simple",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.paymentMethods,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "paymentMethods", $$v);
      },
      expression: "giftcard.paymentMethods"
    }
  })], 1) : _vm._e(), _c('v-divider'), _vm.siteInfo.pointEnabled ? _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("포인트결제")])]), _c('v-spacer'), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "value": "point",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.paymentMethods,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "paymentMethods", $$v);
      },
      expression: "giftcard.paymentMethods"
    }
  })], 1) : _vm._e()], 1), _vm.siteInfo.paymentControlEnabled ? _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_vm._l(_vm.giftcard.payments, function (payment, index) {
    return [0 < index ? _c('v-divider', {
      key: `divider` + index
    }) : _vm._e(), _c('v-card-title', {
      key: index
    }, [_c('span', {
      staticClass: "subtitle-2"
    }, [payment.gateway == `toss` ? _c('b', [_vm._v("토스")]) : payment.gateway == `smartro` ? _c('b', [_vm._v("페이레터(스)")]) : payment.gateway == `danal` ? _c('b', [_vm._v("다날")]) : payment.gateway == `코페이` ? _c('b', [_vm._v("다날")]) : _c('b', [_vm._v(_vm._s(payment.gateway))]), payment.method == `creditcard` ? _c('b', [_vm._v("(신용카드)")]) : _vm._e(), payment.method == `mobile` ? _c('b', [_vm._v("(휴대폰)")]) : _vm._e(), payment.method == `simple` ? _c('b', [_vm._v("(간편결제)")]) : _vm._e()]), _c('v-spacer'), _c('span', {
      staticClass: "subtitle-2 mr-2"
    }, [_c('b', [_vm._v("(사용/미사용)")])]), _c('v-switch', {
      staticClass: "d-inline-block mt-0 my-auto",
      attrs: {
        "color": "red lighten-2",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: payment.disabled,
        callback: function ($$v) {
          _vm.$set(payment, "disabled", $$v);
        },
        expression: "payment.disabled"
      }
    })], 1)];
  })], 2) : _vm._e(), _vm.siteInfo.pointEnabled ? _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("구매가능 등급")])]), _c('v-spacer'), _c('span', [_c('v-select', {
    staticClass: "mt-0",
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "items": _vm.levels,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.levelCode,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "levelCode", $$v);
      },
      expression: "giftcard.levelCode"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("품절여부 (판매 / 품절)")])]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.soldout,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "soldout", $$v);
      },
      expression: "giftcard.soldout"
    }
  })], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("진열여부 (진열안함 / 진열)")])]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "onDisplay", $$v);
      },
      expression: "giftcard.onDisplay"
    }
  })], 1)], 1)], 1)], 1), _vm.ready ? _c('giftcard-options', {
    staticClass: "mt-4",
    attrs: {
      "giftcard": _vm.giftcard,
      "thumb": _vm.thumb,
      "images": _vm.images
    },
    on: {
      "update:thumb": [function ($event) {
        _vm.thumb = $event;
      }, function (file) {
        return _vm.uploadThumb(file);
      }],
      "update:images": [function ($event) {
        _vm.images = $event;
      }, function (file) {
        return _vm.uploadImage(file, 0);
      }]
    },
    model: {
      value: _vm.giftcard.options,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "options", $$v);
      },
      expression: "giftcard.options"
    }
  }) : _vm._e(), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_c('naver-smarteditor', {
    model: {
      value: _vm.giftcard.content,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "content", $$v);
      },
      expression: "giftcard.content"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.ready
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }